import React from 'react';
import { CurveMask } from '../CurveMask';
import { useRouter } from 'next/router';
import { SliderWrapper } from 'components/SliderWrapper';
import { Slider } from 'components/SliderVanilla';
import { HeroWrapperContainer } from 'components/CorpVideoHero/CorpVideoHeroWrapperContainer';
import { Background, HeroContainer, MaskBottom } from 'components/CorpVideoHero/styles';
import { BannerContent } from 'components/CorpVideoHero/components/BannerContent';
import { BannerSize, ICorpVideoHeroProps } from 'components/CorpVideoHero/types';
import clsx from 'clsx';

interface ICorpVideoHeroCarouselProps {
  corpVideoHero: ICorpVideoHeroProps[];
  copy: any;
  bannerSize: BannerSize;
}

export const CorpVideoHeroCarousel: React.FC<ICorpVideoHeroCarouselProps> = ({
  corpVideoHero = [],
  copy,
  bannerSize,
}: ICorpVideoHeroCarouselProps) => {
  const { locale: market } = useRouter();

  const sliderSettings = {
    slidesToScroll: 1,
    slidesToShow: 1,
    adaptiveHeight: false,
    infinite: false,
    arrows: false,
    isStaticArrows: true,
    dots: true,
    lazyLoad: 'ondemand',
    centerMode: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <HeroWrapperContainer data-test="hero-wrapper-container">
      <Background className="curve-mask-top" $customColor={'rgb(1, 14, 84)'}>
        <MaskBottom className="curve-mask-bottom">
          <HeroContainer data-test="hero-container">
            <SliderWrapper>
              <Slider
                data-test="card-carousel-slider"
                {...sliderSettings}
                isStaticArrows={true}
                className={clsx({
                  'h-[577px] md:h-[400px]': bannerSize === 'short',
                  'h-[580px]': bannerSize === 'tall',
                })}
              >
                {corpVideoHero.map((item, index) => {
                  return (
                    <BannerContent
                      key={index}
                      bannerImage={item.bannerImage}
                      mobileImage={item.mobileImage}
                      title={item.title ?? ''}
                      superTitle={item.superTitle ?? ''}
                      description={item.description ?? ''}
                      videoId={item.videoId ?? ''}
                      imageAlt={item.imageAlt}
                      copy={copy}
                      bannerSize={item.bannerSize}
                      textColor={item.textColor}
                      contentAlignment={item.contentAlignment}
                      verticalAlignment={item.verticalAlignment}
                      linkComponent={item.linkComponent}
                      darkOverlay={item.darkOverlay}
                    />
                  );
                })}
              </Slider>
            </SliderWrapper>
          </HeroContainer>
        </MaskBottom>
      </Background>
      <CurveMask />
    </HeroWrapperContainer>
  );
};
